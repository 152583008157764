<style lang="scss">

  .timeline-index {
    text-align: left;

    .container {
      background: url('/assets/media/editions/locaweb.png') no-repeat;
      background-size: contain;
      background-position: top left;

      .header {
        width: 100%;
        min-height: calc(100vh - 120px);
        position: relative;
        top: 150px;

        h2 {
          font-size: 32px;
          font-weight: 300;
          margin-top: 20px;
        }

        @include tablet {
          height: 400px;
          top: 70px;
          padding: 0 60px;
        }

        .title {
          font-size: 4.5rem;
          line-height: 82px;
          font-weight: 500;
          margin-top: 30px;
          letter-spacing: 2px;
          text-transform: uppercase;
          font-family: $title-font;

          @include tablet {
            font-size: 50px;
            line-height: 60px;
          }
        }

        &-roll {
          text-align: center;
          font-weight: 100;
          height: 20px;
          position: absolute;
          margin: 0 auto;
          left: 50%;
          top: 300px;

          .arrow {
            width: 16px;
            vertical-align: middle;
            margin-left: 5px;
          }
        }
      }
    }
  }

</style>

<template lang="pug">

  .timeline-index
    .container
      .header
        Title Marcos históricos
        h1.title Linha do tempo
        h2 Acompanhe nossa evolução
        p.header-roll Role a página
          img.arrow(src="@/assets/img/editions/down-arrow.svg")

      .body
        Title(side='full') Início

        FullTimeline(:timeline="timeline")

</template>

<script>

import FullTimeline from './components/full-timeline.vue';

export default {
  name: 'Timeline',

  components: {
    FullTimeline
  },

  data() {
    return {
      timeline: []
    };
  },

  mounted() {
    this.$api.get('/timelines/?_sort=ano:ASC')
      .then((response) => {
        this.timeline = response.data;
      });
  }
};

</script>
